
















































































































import {Component, Vue} from "vue-property-decorator";
import bus from "@/bus";
import Contextmenu from 'vue-context-menu'

@Component({
  components: {
    Contextmenu
  }
})
export default class Material extends Vue {
  public MaterialGrouping = [];
  public MaterialList = [];
  public selectIndex = 0;
  public groupId = 0;
  public visible = false
  public deleId = 0
  public visibleUpFile = false
  public confirmLoading = false
  public groupingName = ""
  public pageSize = 20
  public pageNum = 1
  public totality = 0
  public files: Array<File> = []
  public deleMaterialId: Array<string> = []
  public okText = "添加"
  public cancelText = "取消"
  public isOk = false
  public form = {
    name: '',
    groupId: "",
    screenType: "1"
  }
  public rules = {
    name: [{required: true, message: '请输入模板名称', trigger: 'blur'}],
    groupId: [{required: true, message: '请选择模板分组', trigger: 'change'}]
  }
  public labelCol = {span: 4}
  public wrapperCol = {span: 14}

  mounted() {
    window.onresize = () => {
      this.docSize()
    }
    this.getGrouping()
  }

  public onContextShow(data) {
    this.deleId = data;
    (this.$refs.contextmenu as any).open()
  }

  public errImg(item) {
    console.log(item);
  }

  public editTemp(id, type) {
    console.log(type)
    let routeData = this.$router.resolve({
      name: "EditTemp",
      query: {
        id,
        type
      }
    })

    setTimeout(() => {
      window.open(routeData.href, '_blank')
    }, 300)
  }

  // 删除分组
  public onDeleteGrouping() {
    bus.$post("/material/removeMaterialGroup", {
      materialGroupId: this.deleId
    }).then(res => {
      this.getGrouping()
      bus.$elementMessage("删除成功", "error")
    }).catch(err => {
      bus.$elementMessage(err, "error")
    })
  }

  // 修改元素大小
  public docSize() {
    let idoc = document.getElementsByClassName("MaterialList-item")

    for (let i = 0; i < idoc.length; i++) {
      const width = idoc[i].clientWidth;
      (idoc[i] as any).style.height = (width * 1920 / 1080) + "px"
    }
  }

  // 获取素材
  public getMaterial(groupId) {
    this.groupId = groupId
    bus.$post('/material/getTemplateList', {
      "pageSize": this.pageSize,
      "pageNum": this.pageNum,
      groupId
    }).then(res => {
      this.MaterialList = res.data.list
      this.totality = res.data.totalNum

      this.$nextTick(() => {
        this.docSize()
      })

    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 点击分组
  public seleteMaterial(index, id) {
    this.selectIndex = index
    this.getMaterial(id)
  }

  // 获取分组
  public getGrouping() {
    bus.$post('/material/getTemplateGroupList').then(res => {
      this.MaterialGrouping = res.data.groupList
      if (this.MaterialGrouping.length) {
        this.getMaterial(this.MaterialGrouping[0].materialGroupId)
      }

    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

  // 添加分组
  public addGrouping() {
    this.visible = true;
  }

  // 提交分组
  public handleOk(e) {
    bus.$post("/material/addTemplateGroup", {
      groupName: this.groupingName
    }).then(res => {
      bus.$elementMessage(res.msg, 'success')
      this.visible = false
      this.getGrouping()
    }).catch(err => {
      bus.$elementMessage(err, "error")
    })
  }

  // 取消分组
  public handleCancel(e) {
    this.groupingName = ""
    this.visible = false;
  }

  // 添加模板
  public addTemp() {
    this.visibleUpFile = true
  }

  // 添加模板
  public handleFile() {
    (this.$refs.ruleForm as any).validate(valid => {
      if (valid) {
        bus.$post('/template/addTemplate', {
          templateName: this.form.name,
          type: this.form.screenType,
          groupId: this.form.groupId
        }).then(res => {
          this.visibleUpFile = false
          this.getMaterial(this.groupId)
          setTimeout(() => {
            let routeData = this.$router.resolve({
              name: "EditTemp",
              query: {
                id: res.data.materialId,
                type: this.form.screenType
              }
            })

            setTimeout(() => {
              window.open(routeData.href, '_blank')
            }, 300)
          })
        }).catch(err => {
          bus.$elementMessage(err, 'error')
        })

      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }

  // 删除素材
  public deleteMaterial(materialIds) {
    this.$confirm({
      title: '删除模板',
      content: '确定要删除该模板？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        bus.$post('/material/removeMaterial', {
          materialIds: [materialIds]
        }).then(res => {
          bus.$elementMessage(res.msg, 'success')
          this.deleMaterialId = []
          this.getMaterial(this.groupId)
        }).catch(err => {
          bus.$elementMessage(err, 'error')
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }


  // 关闭弹窗
  public handleCancelUp() {
    (this.$refs.ruleForm as any).resetFields();
    this.visibleUpFile = false
  }

  // 分页
  onShowSizeChange(current) {
    this.pageNum = current
  }

}
